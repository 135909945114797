import styled, { createGlobalStyle } from 'styled-components';

import { fontFamily, colours } from '../style';

export const Signal = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;

  span {
    background-color: rgba(0, 0, 0, 0.85);
    color: ${colours.white};
    text-shadow: 0.13em 0.13em 0 ${colours.black};
    font-size: 1.5vmax;
    font-family: ${fontFamily};
    text-transform: uppercase;
    display: block;
    padding: 0.115em 0.715em 0.215em;
  }
`;

export const Screen = styled.canvas`
  width: 100%;
  height: 100%;
  transform: scale(1.75);
  z-index: 0;
`;

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    cursor: none;
    overflow: hidden;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
