import { css } from 'styled-components';

export const animations = css`
  /* Blink */
  @keyframes blink {
    to {
      visibility: hidden;
    }
  }

  .blink {
    animation: blink 2.5s steps(3, start) infinite;
  }

  /* Zoom */
  @keyframes zoom {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1.35, 1.35);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .zoom {
    animation: 2.25s ease 0s normal none infinite running zoom;
  }

  /* Fade In Up */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      margin-top: 2.5em;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }

  .fadeInUp {
    opacity: 0;
    animation: fadeInUp 0.5s ease-in-out 0s forwards;
  }
`;
