import styled, { createGlobalStyle, css } from 'styled-components';

import { colours } from '../style';

import { type IContact } from './types';

const fullSpace = css`
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  ${fullSpace};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-colour);
  text-align: center;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  width: 15vmax;
  margin-bottom: 2vmin;
  @media (orientation: portrait) {
    max-width: 75%;
    height: auto;
  }
  @media (orientation: landscape) {
    max-height: 15%;
    width: auto;
  }
`;

export const Icon = styled.span`
  font-size: 12.5vmin;
  cursor: pointer;
`;
export const Contact = styled.p<IContact>`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin: 0;
  white-space: nowrap;

  font-size: ${(props) => {
    switch (props.$what) {
      case 'phone':
        return '8.75vmin';
      case 'email':
        return '6.25vmin';
    }
  }};

  &:last-of-type {
    margin-bottom: 4.5vmin;
  }
`;

export const Name = styled.h1`
  font-size: 10.75vmin;
  font-family: 'Arvo', serif;
  margin: 0 0 0.2em 0;
  line-height: 120%;
  text-transform: uppercase;

  span {
    display: block;

    &::first-letter {
      font-size: 11.75vmin;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  :root {
    /* Light mode. */
    @media (prefers-color-scheme: light) {
      --background-colour: ${colours.white};
      --text-colour: ${colours.darkGrey};
    }

    /* Dark mode. */
    @media (prefers-color-scheme: dark) {
      --background-colour: ${colours.darkGrey};
      --text-colour: ${colours.white};
    }
  }

  ::selection {
    background: var(--text-colour);
    color: var(--background-colour);
  }

  html,
  body,
  #root {
    ${fullSpace};
    background-color: var(--background-colour);
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
  }
  .hidden {
    display: none;
  }
`;
