export const paths = {
  ROOT: '/',
  VCARD: '/vcard',
  ABOUT: '/about',
  CMYK: '/cmyk',
  HEXTIME: '/hextime',
  MURPHY: '/murphy',
  NOT_FOUND: '/404',
  CATCH_ALL: '*',
};
