import React, { memo, lazy, Suspense } from 'react';

import { Screen as S, GlobalStyle } from './style';
import { Default as Head } from '../Head';

const Channel = lazy(
  async () =>
    await import('../Channel').then((module) => ({ default: module.Channel })),
);

const AV_CHANNEL = '404';

const Screen = memo(() => (
  <S>
    {[...Array(3)].map((_, i) => (
      <div key={`k${i}`} className="row">
        {i < 2
          ? // First two rows.
            [...Array(7)].map((_, j) => <div key={`k${j}`} className="col" />)
          : // Third row.
            [...Array(8)].map((_, k) => (
              <div
                key={`k${k}`}
                className={`col ${[4, 5, 6].includes(k) ? 'sm' : ''}`.trim()}
              />
            ))}
      </div>
    ))}
  </S>
));

Screen.displayName = 'Screen';

const TestCard = memo(() => (
  <>
    <GlobalStyle />
    <Head title={AV_CHANNEL} />
    <Suspense>
      <Channel caption={AV_CHANNEL} icon="error" />
    </Suspense>
    <Screen />
  </>
));

TestCard.displayName = 'TestCard';

export { TestCard };
