import React, { memo } from 'react';
import { BsDownload as Download } from 'react-icons/bs';
import { Navigate, useSearchParams } from 'react-router-dom';

import { GlobalStyle, Container, Name, Contact, Avatar, Icon } from './style';
import { photo } from './photo';
import { Vcard as Head } from '../Head';
import { isEnv, paths } from '../utils/';

const testIdAttr = String(process.env.REACT_APP_TEST_ID_ATTR);

const baseDomain = String(process.env.REACT_APP_BASE_DOMAIN);

const data = {
  firstName: 'Gianluca',
  lastName: 'Agnocchetti',
  phone: '+44 7426 713188',
  email: 'gianluca.agnocchetti@gmail.com',
  website: [`https://${baseDomain}`, `https://about.${baseDomain}`],
  birthday: '18/02/1980',
  photo,
};

const getBirthday = (): string => data.birthday.split('/').reverse().join('');

const getImgSrc = (): string => `data:image/jpeg;base64,${data.photo}`;

const vcard = `
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${data.firstName} ${data.lastName}
N;CHARSET=UTF-8:${data.lastName};${data.firstName};;;
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${data.email.toLowerCase()}
TEL;TYPE=CELL:${data.phone.replace(/\s/g, '')}
${data.website
  .map((url) => `URL;CHARSET=UTF-8:${url.toLowerCase()}\n`)
  .join('')}
BDAY:${getBirthday()}
PHOTO;TYPE=JPEG;ENCODING=b:${data.photo}
END:VCARD
`;

const download = (): void => {
  const file = new Blob([vcard], {
    type: 'text/vcard',
  });
  const element = document.createElement('a');
  element.className = 'hidden';
  isEnv('test') && element.setAttribute(testIdAttr, 'blob-file');
  element.href = URL.createObjectURL(file);
  element.download = 'gnlc.vcf';
  document.body.appendChild(element);
  element.click();
};

const Vcard = memo(() => {
  const [searchParams] = useSearchParams();

  const decode =
    searchParams.get('key') === process.env.REACT_APP_VCARD_DECODE_KEY;

  // 404 if wrong key.
  if (!decode) return <Navigate replace to={paths.NOT_FOUND} />;

  return (
    <>
      <GlobalStyle />
      <Head title="Vcard" />
      <Container className="fadeInUp">
        <Avatar src={getImgSrc()} />
        <Name>
          <span>{data.firstName}</span>
          <span>{data.lastName}</span>
        </Name>
        <Contact $what="phone">{data.phone}</Contact>
        <Contact $what="email">{data.email}</Contact>
        <Icon
          onClick={download}
          className="zoom"
          {...(isEnv('test') ? { [testIdAttr]: 'download-icon' } : {})}
        >
          <Download />
        </Icon>
      </Container>
    </>
  );
});

Vcard.displayName = 'Vcard';

export { Vcard };
