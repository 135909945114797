import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import { Common } from './Common';
import { colours } from '../style';
import { type IHead } from './types';

const Default = memo(({ title }: IHead) => (
  <>
    <Common title={title} />
    <Helmet>
      <meta property="og:title" content={title} />
      <meta
        property="og:url"
        content={typeof window !== 'undefined' ? window.location.href : ''}
      />
      <meta
        property="og:image"
        content={process.env.PUBLIC_URL + '/assets/images/testcard.jpg'}
      />
      <meta name="theme-color" content={colours.channel} />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-tv.png"
        sizes="512x512"
      />
    </Helmet>
  </>
));

Default.displayName = 'Head';

export { Default };
