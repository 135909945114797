import { createGlobalStyle } from 'styled-components';
// import reset from 'styled-reset';

import { animations } from './animations';
import { customFonts } from './customFonts';

const colours = {
  channel: '#00ff00',
  black: '#000',
  darkGrey: '#333',
  lightGrey: '#ddd',
  white: '#fff',
};

const fontFamily = `'VCR OSD Mono', Helvetica, Arial, sans-serif;`;

const GlobalStyle = createGlobalStyle`
  ${customFonts}
  ${animations}

  html,
  body,
  #root {
    margin: 0;
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
  }
`;

export { colours, fontFamily, GlobalStyle };
