import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import { Common } from './Common';
import { colours } from '../style';
import { type IHead } from './types';

const Vcard = memo(({ title }: IHead) => (
  <>
    <Common title={title} />
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Arvo:wght@700&family=Lato:wght@300;700&display=swap"
        rel="stylesheet"
      />
      <meta
        name="theme-color"
        content={colours.darkGrey}
        media="(prefers-color-scheme: dark)"
      />
      <meta
        name="theme-color"
        content={colours.white}
        media="(prefers-color-scheme: light)"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-vcard.png"
        sizes="512x352"
      />
    </Helmet>
  </>
));

Vcard.displayName = 'Head';

export { Vcard };
