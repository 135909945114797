import React, { type ReactNode } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { Redirect } from './Redirect';
import { TV } from './TV';
import { TestCard } from './TestCard';
import { Vcard } from './Vcard';
import { Loading } from './Loading';

import { paths, externals } from './utils/';

export const Router = (): ReactNode => (
  <BrowserRouter basename="/">
    <Routes>
      <Route index element={<TV />} />
      <Route path={paths.VCARD}>
        <Route index element={<Vcard />} />
        {process.env.REACT_APP_VCARD_DECODE_KEY && (
          <Route
            path={`${process.env.REACT_APP_VCARD_DECODE_KEY}`}
            element={
              <Navigate
                replace
                to={`${paths.VCARD}?key=${process.env.REACT_APP_VCARD_DECODE_KEY}`}
              />
            }
          />
        )}
      </Route>
      <Route
        path={paths.ABOUT}
        element={
          <Redirect replace url={externals.ABOUT} loader={<Loading />} />
        }
      />
      <Route
        path={paths.CMYK}
        element={<Redirect replace url={externals.CMYK} loader={<Loading />} />}
      />
      <Route
        path={paths.HEXTIME}
        element={
          <Redirect replace url={externals.HEXTIME} loader={<Loading />} />
        }
      />
      <Route
        path={`${paths.MURPHY}/:number?`}
        element={
          <Redirect
            replace
            url={externals.MURPHY}
            forward
            loader={<Loading />}
          />
        }
      />
      <Route path={paths.NOT_FOUND} element={<TestCard />} />
      <Route
        path={paths.CATCH_ALL}
        element={<Navigate replace to={paths.NOT_FOUND} />}
      />
    </Routes>
  </BrowserRouter>
);
