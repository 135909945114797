import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import { type IHead } from './types';

const Common = memo(({ title }: IHead) => (
  <Helmet>
    <title>{title}</title>
    <meta name="author" content={process.env.REACT_APP_AUTHOR} />
    <meta name="robots" content="noindex,nofollow" />
    <meta name="mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-status-bar-style" content="black" />
    <meta
      name="apple-mobile-web-app-title"
      content={process.env.REACT_APP_AUTHOR}
    />
    <meta name="mobile-web-app-capable" content="yes" />
    <meta name="format-detection" content="telephone=no" />
  </Helmet>
));

Common.displayName = 'Head';

export { Common };
