import styled, { createGlobalStyle, css } from 'styled-components';
import { type RuleSet } from 'styled-components/dist/types';

const colours = {
  row1: [
    '#c0c0c0',
    '#bfbd22',
    '#69bcbc',
    '#67b900',
    '#a83ac1',
    '#a72b2a',
    '#1526bd',
  ],
  row2: [
    '#1525bd',
    '#131313',
    '#a83ac1',
    '#131313',
    '#69bcbc',
    '#131313',
    '#c0c0c0',
  ],
  row3: [
    '#0f224a',
    '#fff',
    '#2c1469',
    '#131313',
    '#090909',
    '#131313',
    '#1d1d1d',
    '#131313',
  ],
};

const getRow = (row: string[]): RuleSet =>
  row.map(
    (colour, index) => css`
      &:nth-child(${index + 1}) {
        background: ${colour};
      }
    `,
  );

export const Screen = styled.div`
  .row {
    display: flex;

    .col {
      box-flex: 1;
      flex: 1 1 auto;
    }

    &:nth-child(1) {
      height: 70vh;

      > .col {
        ${getRow(colours.row1)}
      }
    }
    &:nth-child(2) {
      height: 10vh;

      > .col {
        ${getRow(colours.row2)}
      }
    }
    &:nth-child(3) {
      height: 20vh;

      > .col {
        box-flex: 3;
        flex: 3;

        &.sm {
          box-flex: 1;
          flex: 1;
        }

        ${getRow(colours.row3)}
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    cursor: none;
    overflow: hidden;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
