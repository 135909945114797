import { memo } from 'react';
import { useLocation } from 'react-router-dom';

import { type IRedirect } from './types';

const getPath = (path: string): string =>
  '/' + path.split('/').slice(2).join('/');

const Redirect = memo(({ url, replace, forward, loader }: IRedirect) => {
  const location = useLocation();

  const handleRedirect = (): void => {
    if (forward) url = url + getPath(location.pathname);

    replace ? window.location.replace(url) : (window.location.href = url);
  };

  if (typeof window !== 'undefined') handleRedirect();

  if (loader) return loader;

  return null;
});

Redirect.displayName = 'Redirect';

export { Redirect };
