import React, { type ReactNode } from 'react';
import CacheBuster from 'react-cache-buster';

import { Router } from './Router';
import { Loading } from './Loading';
import { GlobalStyle } from './style';

import packageInfo from '../package.json';

import { isEnv } from './utils/';

const App: React.FC = (): ReactNode => (
  <CacheBuster
    currentVersion={packageInfo.version}
    isEnabled={isEnv('production')}
    isVerboseMode={false}
    loadingComponent={<Loading />}
  >
    <>
      <GlobalStyle />
      <Router />
    </>
  </CacheBuster>
);

export { App };
