import React, { useEffect, memo, lazy, Suspense } from 'react';

import { GlobalStyle, Screen, Signal as S } from './style';
import { Default as Head } from '../Head';

const Channel = lazy(
  async () =>
    await import('../Channel').then((module) => ({ default: module.Channel })),
);

const AV_CHANNEL = 'GNLC-TV';

const Signal = memo(() => (
  <S>
    <span>No Signal</span>
  </S>
));

Signal.displayName = 'Signal';

const TV = memo(() => {
  useEffect(() => {
    require('../lib/noise');
  }, []);

  return (
    <>
      <GlobalStyle />
      <Head title={AV_CHANNEL} />
      <Suspense>
        <Channel caption={AV_CHANNEL} icon="input" />
      </Suspense>
      <Signal />
      <Screen id="tv" />
    </>
  );
});

TV.displayName = 'TV';

export { TV };
