const ascii = `
  ________  _______  .____   _________  
 /  _____/  \\      \\ |    |  \\_   ___ \\ 
/   \\  ___  /   |   \\|    |  /    \\  \\/ 
\\    \\_\\  \\/    |    \\    |__\\     \\____
 \\______  /\\____|__  /_______ \\______  /
        \\/         \\/        \\/      \\/`;

export const logo = (): void => {
  console.info(ascii);
};
