import React, { memo } from 'react';

import { StyledReactLoading } from './style';
import { colours } from '../style';

const Loading = memo(() => (
  <StyledReactLoading type="bubbles" color={colours.lightGrey} />
));

Loading.displayName = 'Loading';

export { Loading };
