import { css } from 'styled-components';

export const customFonts = css`
  @font-face {
    font-family: 'VCR OSD Mono';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('VCR OSD Mono'),
      url('/assets/font/VCR_OSD_MONO_1.woff') format('woff');
  }
`;
